import {
  addYears,
  endOfYear,
  isAfter,
  isBefore,
  startOfToday,
  subDays,
  subYears,
  startOfYear,
  startOfTomorrow,
} from 'date-fns';
import { addMethod, date } from 'yup';

const today = startOfToday();
const unixTime = new Date('1970-01-01');

/**
 * yup Erweiterung zum Validieren, ob ein Datum heute oder innerhalb der letzten X Tage liegt.
 */
addMethod(date, 'todayOrLastXDays', function todayOrLastXDays(tage: number, message?: string) {
  return this.test({
    test(value) {
      // falsy Werte müssen ignoriert werden, ansonsten würden wir automatisch den wert verpflichtend machen
      if (!value) {
        return true;
      }

      const minDate = subDays(today, tage);
      return !isBefore(value, minDate) && !isAfter(value, today);
    },
    message:
      message ??
      `Das Datum kann nur das heutige sein oder bis zu ${tage} Tage in der Vergangenheit liegen`,
  });
});

/**
 * yup Erweiterung zum Validieren, ob ein Datum heute oder in der Zukunft ist.
 */
addMethod(date, 'todayOrAfter', function todayOrAfter(message?: string) {
  return this.test({
    name: 'todayOrAfter',
    test(value) {
      // falsy Werte müssen ignoriert werden, ansonsten würden wir automatisch den wert verpflichtend machen
      if (!value) {
        return true;
      }
      return !isBefore(value, today);
    },
    message: message ?? 'Das Datum kann nur das heutige oder ein in der Zukunft liegendes sein',
  });
});

/**
 * yup Erweiterung zum Validieren, ob ein Datum zwischen dem 01.01.1970 und dem 31.12. in X Jahren liegt
 */
addMethod(date, 'maxYearsInFuture', function maxYearsInFuture(jahre: number, message?: string) {
  return this.test({
    name: 'maxYearsInFuture',
    test(value) {
      // falsy Werte müssen ignoriert werden, ansonsten würden wir automatisch den wert verpflichtend machen
      if (!value) {
        return true;
      }
      return isBefore(value, endOfYear(addYears(today, jahre))) && isAfter(value, unixTime);
    },
    message:
      message ??
      'Das Datum kann nur zwischen dem 01.01.1970 und dem 31.12. des übernächsten Jahres liegen',
  });
});

addMethod(
  date,
  'betweenMinAndMax',
  function betweenMinAndMax(minYears?: number, maxYears?: number, message?: string) {
    return this.test({
      name: 'betweenMinAndMax',
      test(value) {
        // falsy Werte müssen ignoriert werden, ansonsten würden wir automatisch den wert verpflichtend machen
        if (!value) {
          return true;
        }

        const minDate = subYears(startOfYear(today), minYears ?? 5);
        const maxDate = addYears(endOfYear(today), maxYears ?? 1);

        return isAfter(value, minDate) && isBefore(value, maxDate);
      },
      message:
        message ??
        'Das Datum kann fünf Jahre in der Vergangenheit bis maximal zum 31.12. des Folgejahres liegen',
    });
  },
);

/**
 * yup Erweiterung zum Validieren, ob ein Datum heute oder in der Zukunft ist.
 */
addMethod(date, 'tomorrowOrAfter', function tomorrowOrAfter(message?: string) {
  return this.test({
    name: 'tomorrowOrAfter',
    test(value) {
      // falsy Werte müssen ignoriert werden, ansonsten würden wir automatisch den wert verpflichtend machen
      if (!value) {
        return true;
      }
      return !isBefore(value, startOfTomorrow());
    },
    message: message ?? 'Das Datum kann nur in der Zukunft liegen',
  });
});
